import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import {nl} from '@braid/vue-formulate-i18n';
import yourErrorHandler from '~/libs/error-handler';

Vue.use(VueFormulate, {
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'btn btn-primary';
        case 'box':
          return '';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';
        default:
          return 'text-gray-darker font-sans w-full border border-gray focus:shadow-forms border-box outline-none p-3 rounded';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'px-1';
        default:
          return 'w-full';
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    outer(context) {
      switch (context.classification) {
        case 'box':
          return 'mb-0';
        default:
          return 'mb-4';
      }
    },
    grouping(context) {
      switch (context.classification) {
        default:
          return 'bg-primary';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left';
        default:
          return 'font-medium text-gray-900;';
      }
    },
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-error text-xs mb-1',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    uploadAreaMask: 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder',
  },
  errorHandler: yourErrorHandler,
});
