import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ClientList: () => import('../../components/ClientList.vue' /* webpackChunkName: "components/client-list" */).then(c => wrapFunctional(c.default || c)),
  CustomTable: () => import('../../components/CustomTable.vue' /* webpackChunkName: "components/custom-table" */).then(c => wrapFunctional(c.default || c)),
  DashboardList: () => import('../../components/DashboardList.vue' /* webpackChunkName: "components/dashboard-list" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  ModalClients: () => import('../../components/ModalClients.vue' /* webpackChunkName: "components/modal-clients" */).then(c => wrapFunctional(c.default || c)),
  ModalConfirm: () => import('../../components/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c)),
  ModalEditClient: () => import('../../components/ModalEditClient.vue' /* webpackChunkName: "components/modal-edit-client" */).then(c => wrapFunctional(c.default || c)),
  ModalViewRecallMail: () => import('../../components/ModalViewRecallMail.vue' /* webpackChunkName: "components/modal-view-recall-mail" */).then(c => wrapFunctional(c.default || c)),
  ProductFormList: () => import('../../components/ProductFormList.vue' /* webpackChunkName: "components/product-form-list" */).then(c => wrapFunctional(c.default || c)),
  ProductList: () => import('../../components/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
