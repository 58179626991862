import {ApolloLink} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';

export default function (app) {
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  // See https://dev.to/marvinrabe/file-upload-with-vue-apollo-client-and-graphql-5emb
  const uploadLink = createUploadLink({
    uri: process.env.API_URL.replace(new RegExp('/api$'), '') + '/graphql',
    fetch: (uri, options) => {
      app.store.dispatch('validation/clearErrors');

      // Add authorization token
      const token = app.$cookies.get('auth._token.local');

      if (token) {
        options.headers.authorization = token;
      }

      return fetch(uri, options);
    },
  });

  return {
    defaultHttpLink: false,
    link: ApolloLink.from([
      errorLink,
      uploadLink,
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}
