//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  mounted() {
    console.log(`[${this.error.statusCode || 0}] ${this.error.message}`);
    console.log(this.error);
  },
  head() {
    return {
      title: this.page.seo ? this.page.seo.meta_title : this.page.title,
    };
  },
};

