import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66412ada = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8999992c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _406b2bd1 = () => interopDefault(import('../pages/recalls/create.vue' /* webpackChunkName: "pages/recalls/create" */))
const _0545b12d = () => interopDefault(import('../pages/auth/password/reset/index.vue' /* webpackChunkName: "pages/auth/password/reset/index" */))
const _29380c2f = () => interopDefault(import('../pages/auth/password/reset/_token.vue' /* webpackChunkName: "pages/auth/password/reset/_token" */))
const _0dc0c318 = () => interopDefault(import('../pages/recalls/_uuid/index.vue' /* webpackChunkName: "pages/recalls/_uuid/index" */))
const _7b589f36 = () => interopDefault(import('../pages/recalls/_uuid/stats.vue' /* webpackChunkName: "pages/recalls/_uuid/stats" */))
const _f7e8df8e = () => interopDefault(import('../pages/recalls/_uuid/_submission_uuid.vue' /* webpackChunkName: "pages/recalls/_uuid/_submission_uuid" */))
const _648a8992 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _66412ada,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/auth/login",
    component: _8999992c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___nl"
  }, {
    path: "/recalls/create",
    component: _406b2bd1,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-create___nl"
  }, {
    path: "/auth/password/reset",
    component: _0545b12d,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___nl"
  }, {
    path: "/en/auth/login",
    component: _8999992c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/recalls/create",
    component: _406b2bd1,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-create___en"
  }, {
    path: "/en/auth/password/reset",
    component: _0545b12d,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___en"
  }, {
    path: "/en/auth/password/reset/:token",
    component: _29380c2f,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset-token___en"
  }, {
    path: "/auth/password/reset/:token",
    component: _29380c2f,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset-token___nl"
  }, {
    path: "/en/recalls/:uuid",
    component: _0dc0c318,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid___en"
  }, {
    path: "/en/recalls/:uuid?/stats",
    component: _7b589f36,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid-stats___en"
  }, {
    path: "/en/recalls/:uuid?/:submission_uuid",
    component: _f7e8df8e,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid-submission_uuid___en"
  }, {
    path: "/en/:slug",
    component: _648a8992,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en"
  }, {
    path: "/recalls/:uuid",
    component: _0dc0c318,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid___nl"
  }, {
    path: "/recalls/:uuid?/stats",
    component: _7b589f36,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid-stats___nl"
  }, {
    path: "/recalls/:uuid?/:submission_uuid",
    component: _f7e8df8e,
    pathToRegexpOptions: {"strict":true},
    name: "recalls-uuid-submission_uuid___nl"
  }, {
    path: "/",
    component: _66412ada,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/:slug",
    component: _648a8992,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
