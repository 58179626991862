import {page as pageQuery} from '~/graphql/queries/page';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  pageTitle: '',
});

export const actions = {
  /* async nuxtServerInit({dispatch}) {
    await dispatch('storeDispatchFunc');
  }, */
  async getPage({commit}, segments = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        segments: segments === '' ? 'home' : segments,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_PAGE_TITLE(state, payload) {
    state.pageTitle = payload;
  },
};
