//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  data: () => ({
    mobileMenuOpen: false,
  }),
  computed: {
    ...mapState({
      pageTitle: state => state.pageTitle,
    }),
  },
  methods: {
    async logout() {
      await this.$apolloProvider.defaultClient.cache.reset();
      await this.$auth.logout();
    },
  },
};
