export default {
  'Sign in': 'Inloggen',
  'Sign out': 'Uitloggen',
  Search: 'Zoeken',
  'Forgot password?': 'Wachtwoord vergeten?',
  'Email address': 'E-mailadres',
  Password: 'Wachtwoord',
  'Password confirmation': 'Wachtwoord bevestiging',
  'Register as employee': 'Registreer als werknemer',
  Register: 'Registreren',
  'Setup password': 'Wachtwoord instellen',
  'Choose a password': 'Kies een wachtwoord',
  'Repeat password': 'Wachtwoord herhalen',
  Activate: 'Activeren',
  'Your profile': 'Jouw profiel',
  'Password reset': 'Wachtwoord vergeten',
  'Request password reset': 'Wachtwoord herstellen',
  'If you have lost your password you can request here a new one. Fill in the form below to receive an email with the instructions to reset your password.': 'Met onderstaand formulier kun je je wachtwoord opnieuw instellen. Je zult een e-mail ontvangen met verdere instructies.',
  'Set new password': 'Nieuw wachtwoord instellen',
  'Save password': 'Wachtwoord opslaan',
  'Your new password has been saved.': 'Je nieuwe wachtwoord is opgeslagen.',
  'Sending...': 'Verzenden...',
  'This invitation isn\'t valid (anymore). Please contact your buyer.': 'Deze uitnodiging is niet (meer) geldig. Neem contact op met uw inkoper.',
  'Invalid CSV headers': 'Ongeldige CSV kolommen',
  'Invalid product at row {row}': 'Ongeldig product in rij {row}',
  'Invalid CSV data at row {row}': 'Ongeldige data in rij {row}',
  'Invalid email at row {row}': 'Ongeldig e-mailadres in rij {row}',
  'Invalid date at row {row}': 'Ongeldige datum in rij {row}',
  'Invalid CSV data': 'Ongeldige CSV data',
};
